<section class="header container-content" id="header__unique">
    <div class="row row_header between-xs navbar__santander">
        <div class="row middle-xs mx-0 item_icon">
            <div class="col">
                <a (click)="redirectHome()">
                    <img [src]="logo" alt="Santander Unique" class="logo-unique">
                </a>
            </div>
        </div>

        <div class="row middle-xs mx-0 item_menu">
            
            <div class="col navbar__items">

                <div *ngFor="let item of menuList"  class="href__item">
                    <ng-container *ngIf="item.btnBanner?.type == 'INTERNAL'; else withoutRedirect ">
                        <a  (click)="saveGtm(item)" [href]="item.btnBanner.destination" [target]="item.btnBanner.target">
                            {{item.label}}
                        </a>
                    </ng-container>

                    <ng-template #withoutRedirect >
                        <a (click)="goOnClick(item)">
                            {{item.label}}
                        </a>
                    </ng-template>
                    
                </div>

                <div class="item_plus subList_icon" *ngIf="!!this.subMenu.label && this.subMenu.list.length > 0">
                    <a>{{subMenu.label}}</a>
                    <img [src]="subMenu.urlIcon" [alt]="subMenu.label" >
                    <ul class="dropdown-content">
                        <li *ngFor="let item of subMenu.list" class="no_list_style">
                            <ng-container *ngIf="item.btnBanner?.type == 'INTERNAL'; else withoutRedirectSubMenu">
                                <a class="item_text_header" (click)="saveGtm(item)" [href]="item.btnBanner.destination" [target]="item.btnBanner.target">
                                    {{ item.label }}
                                </a>
                            </ng-container>
                            
                            <ng-template #withoutRedirectSubMenu>
                                <a class="item_text_header" (click)="goOnClick(item)">
                                    {{item.label}}
                                </a>
                            </ng-template>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="navNone " [ngClass]="showList ? 'navList' : 'navListNone'">
                <div class="href__item_login_movil bg-principal" *ngIf="showBtnLogin && isHomePublic">
                    <div [innerHTML]="textMobil"></div>
                    <div class="col text-end">
                        <button class="btn_primary" (click)="login(true)">{{textLogin}}</button>
                    </div>
                </div>

                <div *ngFor="let item of menuListMovil"  class="href__item" >

                    <ng-container *ngIf="item.btnBanner?.type == 'INTERNAL'; else redirectMovil ">
                        <a (click)="saveGtm(item)" [href]="item.btnBanner.destination" [target]="item.btnBanner.target">
                            <img [src]="item.urlIcon" [alt]="item.label" >
                            {{item.label}}
                        </a>
                    </ng-container>
                    
                    <ng-template #redirectMovil>
                        <a  (click)="goOnClick(item, true)">
                            <img [src]="item.urlIcon" [alt]="item.label" >
                            {{item.label}}
                        </a>
                    </ng-template>

                </div>
            </div>
            
            <ng-container *ngIf="isUserSignIn; else showBtnToLogin">
                <div class="row end-xs mx-0 px-1">
                    <div class="box" name="session__login_box" (click)="showDataSession()">
                        <div name="session__login_abbreviation" class="abbreviation">{{abbreviation}}</div>
                        <div name="session__login_data" class="session__login_data">
                            <p name="session__login_username" class="username" [innerHtml]="userName"></p>
                            <p name="session__login_lastlogin" class="last__login" [innerHtml]="lastLogin"></p>
                        </div>
                    </div>
                    <div name="session__logout_data_box" class="data_box" [ngStyle]="{'display': styleDisplay}">
                        <div name="session__logout_container_data" class="contentainer__data">
                            <div name="session__logout_container">
                                <div name="session__logout_abbreviation" class="abbreviation m__ico">{{abbreviation}}</div>
                                <p name="session__logout_usename" class="username" [innerHtml]="userName"></p>
                                <p name="session__logout_last__login" class="last__login" [innerHtml]="lastLogin"></p>
                                <button (click)="logout()">{{textLogout}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #showBtnToLogin>
                <div class="col text-end btn__init__session" *ngIf="showBtnLogin && (isUserSignIn || isHomePublic)">
                    <button class="btn_primary" (click)="login()">{{textLogin}}</button>
                </div>
                <div class="btn__burger__menu" *ngIf="isHomePublic" (click)="statusChangeMenuHamburger()">
                    <input type="checkbox" name="btn_burger" id="btn_burger" class="btn_container_burger" >
                    <label class="navNone" for="btn_burger"></label>
                    <div class="container_hamburger">
                        <div class="line" [ngClass]="showList ? 'item_hbr1' : '' "></div>
                        <div class="line" [ngClass]="showList ? 'item_hbr2' : '' "></div>
                        <div class="line" [ngClass]="showList ? 'item_hbr3' : '' "></div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</section>