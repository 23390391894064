import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSerializer } from '@angular/router';
import { LayoutComponent } from '../shared/layout/layout.component';

const routes: Routes = [
  { path: 'campaign', redirectTo: 'campaign/landing', pathMatch: 'full' },
  {
    path: 'home',
    component: LayoutComponent,
    children: [
      {
        path: 'pillar/:slug',
        loadChildren: () => import('../pages/interiors/interiors.module').then( m => m.InteriorsModule )
      },
      {
        path: '',
        loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule)
      }
    ]
  },
  {
    path: 'legal/:slug',
    component: LayoutComponent,
    loadChildren: () => import('../pages/terms-conditions/terms-conditions.module').then( m => m.TermsConditionsModule )
  },
  {
    path: 'frequent-questions',
    component: LayoutComponent,
    loadChildren: () => import('../pages/frequent-questions/frequent-questions.module').then( m => m.FrequentQuestionsModule )
  },
  {
    path: 'participating-cards/:slug',
    component: LayoutComponent,
    loadChildren: () => import( '../pages/participating-cards/participating-cards.module' ).then( m => m.ParticipatingCardsModule )
  },
  {
    path: 'campaign',
    component: LayoutComponent,
    children: [
      {
        path: 'landing',
        loadChildren: () => import('../pages/landing-campaigns/landing-campaigns.module').then(m => m.LandingCampaignsModule)
      },
      {
        path: 'billing/:slug',
        loadChildren: () => import('../pages/campaign-billing/campaign-billing.module').then(m => m.CampaignBillingModule)
      },
      {
        path: 'inscription/:slug',
        loadChildren: () => import('../pages/campaign-inscription/campaign-inscription.module').then(m => m.CampaignInscriptionModule)
      },
      {
        path: 'informative/:slug',
        loadChildren: () => import('../pages/campaign-informative/campaign-informative.module').then(m => m.CampaignInformativeModule)
      },
      {
        path: 'expired',
        loadChildren: () => import('../commons/expired-campaign/expired-campaign.module').then(m => m.ExpiredCampaignModule)
      }
    ]
  },
  {
    path: 'identified',
    loadChildren: () => import('../pages/idp/idp.module').then(m => m.IdpModule)
  },
  {
    path: 'version',
    loadChildren: () =>
      import('../pages/version/version.module').then(m => m.VersionModule)
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled', malformedUriErrorHandler: (error: URIError, urlSerializer: UrlSerializer, url: string) => {
      const temp: string[] = url.split('&');
      const arrayUrl: string[] = temp.map((param: string) => {
        if (param.includes('error_description')) {
          const detail: string[] = param.split("%20");
          const detailformated: string[] = detail.map((str: string) => str.replace(/%/gi, ''));
          return detailformated.join('%20');
        } else {
          return param;
        }
      });
      return urlSerializer.parse(arrayUrl.join('&'));
    }
  })],
  exports: [RouterModule]
})
export class UniqueFrontRoutingModule { }
