import { Injectable } from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { FrontConfigService } from '../front-config/front-config.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Injectable({ providedIn: 'root' })
export class UserActivityService {

  private timer: ReturnType<typeof setTimeout> | undefined;
  private events: string[] = ['keydown', 'click'];
  private inactivity$: Subject<boolean> = new Subject<boolean>();
  private sessionTime: number = (5 * 60);
  

  idleState = 'sin iniciar';
  lastPing?: Date | any = null;

  constructor(
    private configService: FrontConfigService,
    private authentication: AuthenticationService,
    private idle: Idle,
    private keepalive: Keepalive  ) {
      this.setValues();
      this.setIdle();
  }

  public setUp() {
    //iniciamos idle
    this.idle.watch();
    // this.inactivity$.next(true);
    // this.events.forEach(event => {
    //   fromEvent(document, event).pipe(
    //     takeUntil(this.inactivity$)
    //   ).subscribe(_ => this.initTimer())
    // }
    // );

    // this.inactivity$.subscribe((status: boolean) => {
    //   if (!status) {
    //     this.authentication.logout();
    //   }
    // });
  }

  private initTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.inactivity$.next(false), this.sessionTime);
  }

  private setValues(): void {
    const config: any = this.configService.getParams("userActivity");
    if (!!config) {
      this.events = config.events;
      // this.sessionTime = (config.sessionTime * 60) * 1000;
      this.sessionTime = (config.sessionTime * 60);
    }
  }

  setIdle() {
    // seteamos el tiempo en el que empezara a contar la inactividad
    this.idle.setIdle(5);
    // setemaos el tiempo de inactividad. Este tiempo y el de arriba se suman para obtener el tiempo real de inactividad.
    this.idle.setTimeout(this.sessionTime);
    // eventos a detectar
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe( () => {
      this.idleState = 'reiniciamos el tiempo';
      // console.log(this.idleState);
      this.reset();
    });

    this.idle.onTimeout.subscribe( () => {
      this.idleState = 'se cabo el tiempo';
      // console.log(this.idleState);
      this.authentication.logout();
      this.idle.stop();
    });

    this.idle.onIdleStart.subscribe( () => {
      this.idleState = 'inicio el tiempo';
      // console.log(this.idleState);
    })

    this.idle.onTimeoutWarning.subscribe( (contador) => {
      this.idleState = `el tiempo termina en ${contador}`;
      // console.log(this.idleState);
    });

    //seteamos el intervalor de ping
    this.keepalive.interval(1);
    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
  }

  reset() {
    this.idle.watch();
    this.idleState = 'iniciamos';
  }
}
