import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alertSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private menuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  set setAlertSubject( openModal: any ){
    this.alertSubject.next(openModal);
  }

  get getAlertSubject(){
    return this.alertSubject.asObservable();
  }

  set setMenuSubject( displayedMenu: boolean ) {
    this.menuSubject.next(displayedMenu);
  }

  get getMenuSubject() {
    return this.menuSubject.asObservable();
  }

}
